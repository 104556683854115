import React from 'react';
import PreviewCard from 'components/Globals/PreviewCard';

import HorizontalScroller from 'components/uiLibrary/HorizontalScroller';
import classes from './FeaturedEntities.module.scss';

const FeaturedEntities = ({ data, section, entityType, trackingData, size, variant, preloadImage }) => (
  <HorizontalScroller outset>
    <div className={classes.root}>
      {data?.map(({ [section]: entity }) => (
        <PreviewCard
          key={entity?.id}
          entity={entity}
          entityType={entityType}
          trackingData={{ ...trackingData, entityId: entity?.id, entityName: entity?.name, entityType }}
          size={size}
          variant={variant}
          preloadImage={preloadImage}
        />
      ))}
    </div>
  </HorizontalScroller>
);

export default FeaturedEntities;
