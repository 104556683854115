import React, { Fragment, useMemo } from 'react';
import { useQuery } from 'utils/react-query';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import SpriteIcon from 'components/uiLibrary/SpriteIcon';
import SectionBlock from 'components/Globals/SectionBlock';
import Typography from 'components/uiLibrary/Typography';
import HorizontalScroller from 'components/uiLibrary/HorizontalScroller';
import EntityViewAllCard from 'components/Globals/EntityViewAllCard';
import EntityName from 'components/Globals/EntityName';

import queries from 'containers/Artist/queries';
import { SECTIONS, COMPONENTS, SUB_COMPONENTS } from 'components/Globals/Analytics/constants';

import {
  TP,
  SORT_OPTION_TYPES,
  REPERTOIRE_SECTION_TYPES,
  REPERTOIRE_STATUSES,
  ENTITY_TYPE,
  ENTITY_MAIN_TABS,
} from 'constants/index';

import { useTranslation } from 'src/i18n';
import usePageContext from 'utils/hooks/usePageContext';
import useDeviceTypeLayouts from 'utils/hooks/useDeviceTypeLayouts';
import { getComposerFromCreators } from 'utils/composer';

import classes from './RepertoireListing.module.scss';

const futureRepertoireViewTrackingData = {
  section: SECTIONS.REPERTOIRE_LIST,
  component: COMPONENTS.FUTURE,
};

const pastRepertoireViewTrackingData = {
  section: SECTIONS.REPERTOIRE_LIST,
  component: COMPONENTS.PAST,
};

const trackingInlineData = {
  section: SECTIONS.REPERTOIRE_LIST,
  component: COMPONENTS.INLINE,
};

const REPERTOIRE_DISPLAY_STATUS = {
  [REPERTOIRE_STATUSES.UNPREPARED]: `${TP}.FN_ROLE_UNPREPARED`,
  [REPERTOIRE_STATUSES.PREPARED]: `${TP}.FN_ROLE_PREPARED`,
  [REPERTOIRE_STATUSES.PAST]: `${TP}.PERFORMED`,
  [REPERTOIRE_STATUSES.RETIRE]: `${TP}.RETIRED`,
};

const ComposerName = ({ composers = [], trackingData }) =>
  composers.map((composer, index) => (
    <Fragment key={index}>
      <EntityName
        key={composer?.id}
        entityType={ENTITY_TYPE.ARTIST}
        entity={{ id: composer?.profile?.id, name: composer?.profile?.shortName }}
        trackingData={trackingData}
        isRaw
      />
      {index < composers.length - 1 && ', '}
    </Fragment>
  ));

const RepertoireTable = ({ repertoireData, isFutureRepertoire, isDesktop, trackingData }) => {
  const { t } = useTranslation('NS_ENTITY_STUB_PAGE');

  if (!isDesktop) {
    return (
      <div>
        {Object.keys(repertoireData)?.map((composerName, composerIndex) => (
          <div key={composerIndex} className={classes.overviewComposer}>
            <Typography size="14" color="secondary" italic>
              <ComposerName composers={repertoireData[composerName]?.composer} trackingData={trackingData} />
            </Typography>
            {repertoireData[composerName]?.data?.map(work => (
              <div key={work.id} className={classes.overviewWork}>
                <Typography size="14" weight="bold">
                  <EntityName
                    entityType={ENTITY_TYPE.WORK}
                    entity={{ id: work.id, name: work.name }}
                    trackingData={trackingData}
                    isRaw
                  />
                </Typography>
                <div className={classes.overviewRoleInfo}>
                  {work?.roles?.length > 0 ? (
                    <div className={classes.overviewRoleSection}>
                      {work.roles.map((roleName, roleIndex) => (
                        <Typography key={roleIndex} size="14" color="secondary" className={classes.overviewRoleName}>
                          <SpriteIcon icon="role_mask" size="14" />
                          <Typography color="secondary" strikethrough={work?.status === REPERTOIRE_STATUSES.RETIRE}>
                            {roleName} {work?.status === REPERTOIRE_STATUSES.RETIRE && `(${t('RETIRED')})`}
                          </Typography>
                        </Typography>
                      ))}
                    </div>
                  ) : (
                    <Typography color="secondary" className={classes.overviewRoleName}>
                      <SpriteIcon icon="role_mask" size="14" />
                      {work?.professionName}
                    </Typography>
                  )}
                  {isFutureRepertoire && (
                    <Typography size="12" className={classes.status}>
                      {work?.status === REPERTOIRE_STATUSES.UNPREPARED
                        ? t(`${TP}.FN_ROLE_UNPREPARED`)
                        : t(`${TP}.FN_ROLE_PREPARED`)}
                    </Typography>
                  )}
                </div>
              </div>
            ))}
          </div>
        ))}
      </div>
    );
  }

  return (
    <div>
      <div className={classes.header}>
        <Typography size="12">
          {t(`${TP}.m_COMPOSER`)} & {t(`${TP}.m_MUSICALWORK`)}
        </Typography>
        <Typography size="12">{t(`${TP}.FN_ROLE_TITLE`)}</Typography>
        {isFutureRepertoire && <Typography size="12">{t(`${TP}.m_STATUS`)}</Typography>}
      </div>
      <div>
        {Object.keys(repertoireData)?.map((composerName, composerIndex) => (
          <div key={composerIndex} className={classes.composer}>
            <Typography size="14" color="secondary" italic>
              <ComposerName composers={repertoireData[composerName]?.composer} trackingData={trackingData} />
            </Typography>
            {repertoireData[composerName]?.data?.map(work => (
              <div key={work.id} className={classes.work}>
                <Typography size="14" weight="bold" className={classes.workName}>
                  <EntityName
                    entityType={ENTITY_TYPE.WORK}
                    entity={{ id: work.id, name: work.name }}
                    trackingData={trackingData}
                    isRaw
                  />
                </Typography>
                <div className={classes.role}>
                  {work?.roles?.length > 0 ? (
                    work.roles.map((roleName, roleIndex) => (
                      <Typography color="secondary" className={classes.roleName} key={roleIndex}>
                        <SpriteIcon icon="role_mask" size="14" />
                        <Typography color="secondary" strikethrough={work?.status === REPERTOIRE_STATUSES.RETIRE}>
                          {roleName} {work?.status === REPERTOIRE_STATUSES.RETIRE && `(${t('RETIRED')})`}
                        </Typography>
                      </Typography>
                    ))
                  ) : (
                    <Typography color="secondary" className={classes.roleName}>
                      <SpriteIcon icon="role_mask" size="14" />
                      {work?.professionName}
                    </Typography>
                  )}
                </div>
                {isFutureRepertoire && (
                  <Typography size="12" className={classes.status}>
                    {work?.status === REPERTOIRE_STATUSES.UNPREPARED
                      ? t(`${TP}.FN_ROLE_UNPREPARED`)
                      : t(`${TP}.FN_ROLE_PREPARED`)}
                  </Typography>
                )}
              </div>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
};

const RepertoireTableView = ({ entity, sectionProps, type, proArtist, hasCastingToolAccess, filters = {} }) => {
  const { t } = useTranslation('NS_ENTITY_STUB_PAGE');
  const { isDesktop } = useDeviceTypeLayouts();
  const isFutureRepertoire = type === REPERTOIRE_SECTION_TYPES.FUTURE;
  const trackingData = isFutureRepertoire ? futureRepertoireViewTrackingData : pastRepertoireViewTrackingData;

  const { data: repertoireData, isLoading } = useQuery(
    queries.getRepertoireDetails({
      id: entity?.id,
      filters: {
        sort: SORT_OPTION_TYPES.COMPOSERS_ASC?.value,
        ...(filters || {}),
      },
      queryConfig: {
        enable:
          type === REPERTOIRE_SECTION_TYPES.FUTURE
            ? entity?.stats?.repertoire?.future?.exists
            : entity?.stats?.repertoire?.past?.exists,
      },
    }),
  );

  const sectionBlockProps = useMemo(
    () => ({
      ...sectionProps,
      title: t(isFutureRepertoire ? `${TP}.FN_FUTURE_REPERTOIRE` : `${TP}.m_LISTREPERTOIRE`),
    }),
    [isFutureRepertoire, sectionProps, t],
  );

  if (isLoading || (isFutureRepertoire && !proArtist && !hasCastingToolAccess)) {
    return null;
  }

  if (
    (isFutureRepertoire && !repertoireData?.futureRepertoireCount) ||
    (!isFutureRepertoire && !repertoireData?.scheduleRepertoireCount)
  ) {
    return null;
  }

  return (
    <SectionBlock
      count={isFutureRepertoire ? repertoireData?.futureRepertoireCount : repertoireData?.scheduleRepertoireCount}
      {...sectionBlockProps}
      seeAllTrackingData={{ ...trackingData, subComponent: SUB_COMPONENTS.SEE_ALL_CTA }}
    >
      <RepertoireTable
        repertoireData={isFutureRepertoire ? repertoireData?.futureRepertoire : repertoireData?.scheduleRepertoire}
        isFutureRepertoire={isFutureRepertoire}
        isDesktop={isDesktop}
        trackingData={trackingData}
      />
    </SectionBlock>
  );
};

const RepertoireCard = ({ repertoire, trackingData }) => {
  const { t } = useTranslation('NS_ENTITY_STUB_PAGE');
  const { work, workRole, profession, status } = repertoire;
  const composer = getComposerFromCreators(work?.creators);

  return (
    <div className={classes.repertoireCard}>
      <div className={classes.repertoireCard__content}>
        <span className={classes.repertoireCard__info}>
          <Typography color="white" size="12" weight="medium" truncate>
            <EntityName
              entityType={ENTITY_TYPE.WORK}
              entity={{ id: work.id, name: work.original_name }}
              trackingData={trackingData}
              isRaw
            />
          </Typography>
          <Typography color="tertiary" size="12" italic truncate>
            {composer?.profile?.shortName}
          </Typography>
        </span>
        <Typography color="quaternary" size="12" truncate>
          <SpriteIcon icon="role_mask_white" size="12" className={classes.repertoireCard__icon} />
          {workRole?.name || profession?.name}
        </Typography>
      </div>
      <Typography
        size="9"
        className={classnames({
          [classes.prepared]: status === REPERTOIRE_STATUSES.PREPARED,
          [classes.unPrepared]: status === REPERTOIRE_STATUSES.UNPREPARED,
          [classes.past]: status === REPERTOIRE_STATUSES.PAST,
          [classes.retire]: status === REPERTOIRE_STATUSES.RETIRE,
        })}
      >
        {t(REPERTOIRE_DISPLAY_STATUS[status])}
      </Typography>
    </div>
  );
};

const RepertoireInlineView = ({ entity, sectionProps, filters = {} }) => {
  const { t } = useTranslation('NS_ENTITY_STUB_PAGE');
  const { navigate } = usePageContext();
  const linkProps = useMemo(
    () =>
      navigate.getLinkProps({
        entity,
        entityType: ENTITY_TYPE.ARTIST,
        path: ENTITY_MAIN_TABS.REPERTOIRE,
        onlyLinkProps: true,
      }),
    [entity, navigate],
  );
  const query = useMemo(
    () => ({
      id: entity?.id,
      filters: {
        sort: SORT_OPTION_TYPES.COMPOSERS_ASC?.value,
        ...(filters || {}),
      },
      queryConfig: {
        select: res => ({
          data: res?.data || [],
          total: res?.total,
        }),
      },
    }),
    [entity, filters],
  );
  const { data: futureRepertoire } = useQuery(
    queries.getRepertoireDetails({
      ...query,
      filters: {
        status: `${REPERTOIRE_STATUSES.PREPARED}, ${REPERTOIRE_STATUSES.UNPREPARED}`,
        ...query.filters,
      },
      queryConfig: {
        enable: entity?.stats?.repertoire?.future?.exists,
        ...query.queryConfig,
      },
    }),
  );
  const { data: scheduleRepertoire } = useQuery(
    queries.getRepertoireDetails({
      ...query,
      filters: {
        status: `${REPERTOIRE_STATUSES.PAST}, ${REPERTOIRE_STATUSES.RETIRE}`,
        ...query.filters,
      },
      queryConfig: {
        enable: entity?.stats?.repertoire?.past?.exists,
        ...query.queryConfig,
      },
    }),
  );

  const repertoires = useMemo(
    () => [...(futureRepertoire?.data || []), ...(scheduleRepertoire?.data || [])].slice(0, 10),
    [futureRepertoire, scheduleRepertoire],
  );

  if (!repertoires?.length) {
    return null;
  }

  return (
    <SectionBlock
      {...sectionProps}
      title={`${t(`${TP}.FN_FUTURE_REPERTOIRE`)} & ${t(`${TP}.m_LISTREPERTOIRE`)}`}
      seeAllTrackingData={{ ...trackingInlineData, subComponent: SUB_COMPONENTS.SEE_ALL_CTA }}
      linkProps={linkProps}
    >
      <HorizontalScroller>
        <div className={classes.inlineContainer}>
          {repertoires?.map(repertoire => (
            <RepertoireCard key={repertoire?.id} repertoire={repertoire} trackingData={trackingInlineData} />
          ))}
          {entity?.stats?.repertoire?.total > repertoires?.length ? (
            <EntityViewAllCard
              title={`${t(`${TP}.FN_FUTURE_REPERTOIRE`)} & ${t(`${TP}.m_LISTREPERTOIRE`)}`}
              total={entity?.stats?.repertoire?.total}
              linkProps={linkProps}
            />
          ) : null}
        </div>
      </HorizontalScroller>
    </SectionBlock>
  );
};

const RepertoireListing = ({ entity, type, inline = false, sectionProps = {} }) => {
  const { permissions, filters } = usePageContext();
  const { castingToolPermissions, isAdmin } = permissions;
  const hasCastingToolAccess = isAdmin || castingToolPermissions?.hasAccess;
  const proArtist = entity?.stats?.isPro;

  if (inline && (proArtist || hasCastingToolAccess)) {
    return <RepertoireInlineView type={type} entity={entity} sectionProps={sectionProps} filters={filters} />;
  }

  return (
    <RepertoireTableView
      type={type}
      entity={entity}
      sectionProps={sectionProps}
      proArtist={proArtist}
      hasCastingToolAccess={hasCastingToolAccess}
      filters={filters}
    />
  );
};

RepertoireListing.propTypes = {
  type: PropTypes.oneOf([REPERTOIRE_SECTION_TYPES.FUTURE, REPERTOIRE_SECTION_TYPES.PAST]),
};

export default RepertoireListing;
