import React from 'react';

import FeaturedEntities from 'components/Productions/Display/FeaturedEntities';
import Loader from 'components/uiLibrary/Loader';
import SectionBlock from 'components/Globals/SectionBlock';
import queries from 'containers/Artist/queries';

import { useQuery } from 'utils/react-query';
import { useTranslation } from 'src/i18n';
import {
  ENTITY_TYPE,
  FEATURED_ARTIST_ENTITIES_SECTIONS,
  FEATURED_ENTITY_SECTIONS,
  ORGANIZATION_TYPE_IDS,
} from 'constants/index';
import { SECTIONS, COMPONENTS } from 'components/Globals/Analytics/constants';

import classes from './FeaturedArtistEntities.module.scss';

const artistTrackingData = {
  section: SECTIONS.ARTIST_WORKED_WITH,
  component: COMPONENTS.ARTIST_CARD,
};

const orgTrackingData = {
  section: SECTIONS.ORG_COLLABORATED_WITH,
  component: COMPONENTS.ORGANIZATION_CARD,
};

const venueTrackingData = {
  section: SECTIONS.VENUES_PERFORMED_AT,
  component: COMPONENTS.VENUE_CARD,
};

const defaultTrackingData = { section: SECTIONS.RELATED_ENTITIES, component: COMPONENTS.PREVIEW_CARD };

const getTrackingData = section => {
  if (section === FEATURED_ENTITY_SECTIONS.ARTISTS) return artistTrackingData;
  if (section === FEATURED_ENTITY_SECTIONS.ORGANIZATIONS) return orgTrackingData;
  if (section === FEATURED_ENTITY_SECTIONS.VENUES) return venueTrackingData;
  return defaultTrackingData;
};

const FeaturedArtistEntities = ({ entity, section, sectionProps = {}, theme, size, variant }) => {
  const { t } = useTranslation('NS_ENTITY_STUB_PAGE');

  const entityType = section === FEATURED_ENTITY_SECTIONS.ARTISTS ? ENTITY_TYPE.ARTIST : ENTITY_TYPE.ORGANIZATION;

  const { data: preferredEntities, isLoading: preferencesDataLoading } = useQuery(
    queries.getFeaturedEntities({
      id: entity?.id,
      filters: {
        section,
      },
    }),
  );

  const { data: suggestedEntities, isLoading: suggestedDataLoading } = useQuery(
    queries.getSuggestedEntities({
      id: entity?.id,
      entityType,
      ...(section === FEATURED_ENTITY_SECTIONS.VENUES && {
        filters: {
          organization_type_id: ORGANIZATION_TYPE_IDS.VENUE,
        },
      }),
      limit: 6,
      queryConfig: {
        enabled: !preferencesDataLoading && preferredEntities?.data?.length < 1,
        select: response => {
          if (section === FEATURED_ENTITY_SECTIONS.VENUES) {
            return {
              ...response,
              data: response?.data?.map(venue => ({
                [FEATURED_ENTITY_SECTIONS.VENUES]: venue?.organization,
              })),
            };
          }

          return response;
        },
      },
    }),
  );

  if (preferencesDataLoading || (suggestedDataLoading && !preferredEntities?.data?.length)) {
    return <Loader />;
  }

  const sectionsInfo = FEATURED_ARTIST_ENTITIES_SECTIONS[section];

  if (!preferredEntities?.data?.length && !suggestedEntities?.data?.length) {
    return null;
  }

  return (
    <SectionBlock
      className={classes.sectionWrapper}
      styles={classes.sectionWrapper__title}
      title={t(sectionsInfo?.displayTitle, { entityName: entity?.name })}
      {...sectionProps}
    >
      <FeaturedEntities
        data={preferredEntities?.data?.length > 0 ? preferredEntities?.data : suggestedEntities?.data}
        section={section}
        entityType={entityType}
        trackingData={getTrackingData(section)}
        size={size}
        variant={variant}
        theme={theme}
        preloadImage
      />
    </SectionBlock>
  );
};

export default FeaturedArtistEntities;
